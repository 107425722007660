<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      :max-width="width"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span>
            {{ title }}
          </span>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" sd="6" md="6">
              <v-radio-group
                v-model="stock"
                row
                mandatory
                dense
                v-if="radio"
                :readonly="readonly"
                @change="filterlist"
              >
                <v-radio label="Fabriqué" :value="false"></v-radio>
                <v-radio label="Stock" :value="true"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sd="4" md="4">
              <datepicker
                v-if="periode"
                label="Date "
                :edit="true"
                :rules="[(v) => !!v || 'Date obligatoire']"
                :clearable="false"
                v-model="editedItem.date_in"
              ></datepicker>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle>
          {{ subtitle }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12" sd="12" md="12">
              <listitems
                :list="items"
                :qCreate="Qcreate"
                :qDelete="Qdelete"
                :Get_suf="Get_suf"
                :del_disable="
                  del_disable || document.create_uid != $store.state.me.id
                "
                :headers="listheaders"
                :master="master"
                :add="add"
                :del="del"
                :Update="editer"
                :showedit="showedit"
                :showstd="showstd"
                :Total="Total"
                :print="canprint"
                :multiple="multiple"
                @print="print_doc"
                @open="open"
                @hrf="open"
                @rowselect="SelectItem"
                :exp_excel="exp_excel"
                :imp_excel="imp_excel"
                @excel_import="excel_import"
                :key="klist"
                :laoding="laoding"
                @add="sendadd"
                @change="sendchange"
                @delete="senddelete"
                @btn_click="sendclick"
                :kid="kid"
              >
              </listitems>
            </v-col>
            <v-col
              cols="12"
              sd="12"
              md="12"
              v-if="list2 ? list2.length > 0 : false"
            >
              <listitems
                :list="list2"
                :headers="list2headers"
                :master="master"
                :add="false"
                :del="false"
                :Total="Total"
                :print="false"
                @open="open2"
                @hrf="open2"
                @rowselect="SelectItem2"
                :exp_excel="exp_excel"
                :imp_excel="imp_excel"
                :key="klist2"
                :laoding="laoding"
                @btn_click="sendclick2"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" sd="6" md="6">
              <v-alert
                v-if="alert_value"
                v-model="alert_value"
                :type="type_alert"
              >
                {{ message }}
              </v-alert>
            </v-col>
            <v-col cols="12" sd="3" md="3">
              <v-container fill-height>
                <v-layout row justify-center align-center v-if="progress">
                  <v-progress-circular
                    indeterminate
                    :size="30"
                    :width="7"
                    color="purple"
                  ></v-progress-circular>
                </v-layout>
              </v-container>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sd="3" md="3" class="text-right">
              <v-btn
                color="blue darken-1"
                text
                @click.stop="save"
                v-if="btn_save"
                :disabled="change == 0 && btn_save_dis"
              >
                <v-icon left> mdi-content-save-all </v-icon>
                {{ btn_save_caption }}
              </v-btn>

              <v-btn color="blue darken-1" text @click.stop="close">
                {{ fermer }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
let today = new Date().toISOString().slice(0, 10);

import jsPDF from "jspdf";

import { myLogo } from "print/data.js";
import { marges2 } from "print/entete.js";
export default {
  name: "formlist",
  components: {
    listitems: () => import("./ListItems.vue"),
    datepicker: () => import("./DatePicker.vue"),
  },
  props: {
    title: String,
    subtitle: String,
    list: Array,
    list2: Array,
    save_list: Array,
    Qcreate: Object,
    Qdelete: Object,
    Get_suf: String,
    showform: { type: Boolean, default: false },
    listheaders: Array,
    list2headers: Array,
    width: { type: String, default: "700px" },
    Total: { type: Boolean, default: false },
    canprint: { type: Boolean, default: true },
    printdefault: { type: Boolean, default: true },
    progress: { type: Boolean, default: false },
    periode: { type: Boolean, default: false },
    editer: { type: Boolean, default: false },
    add: { type: Boolean, default: false },
    del: { type: Boolean, default: false },
    del_disable: { type: Boolean, default: false },
    showedit: { type: Boolean, default: false },
    showstd: { type: Boolean, default: false },
    master: { type: Boolean, default: true },
    orientation: { type: String, default: "p" },
    btn_save_caption: { type: String, default: "Save" },
    btn_save: { type: Boolean, default: false },
    btn_save_dis: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    exp_excel: { type: Boolean, default: true },
    imp_excel: { type: Boolean, default: false },
    radio: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    laoding: { type: Boolean, default: false },
    totalht: { type: Boolean, default: false },
    kid: { type: String, default: "id" },
    type_alert: { type: String, default: "info" },
    message: String,
    cmd_id: String,
    alert: Boolean,
    exe_tva: Number,
  },
  data() {
    return {
      fermer: "Fermer",
      change: 0,
      items: [],
      klist: 100,
      klist2: 100,
      stock: false,
      return_list: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      document: {},
      document2: {},
      editedItem: {},
    };
  },
  mounted() {
    this.stock = this.readonly;
    if (this.radio) this.filterlist();
    else {
      this.items = this.list;
      this.klist++;
    }
    // this.editedItem.date_in = this.readsession.today;
  },
  watch: {},
  computed: {
    alert_value: {
      get: function () {
        return this.alert;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    sendclick(item) {
      this.$emit("btn_click", item);
    },
    sendclick2(item) {
      this.$emit("btn_click2", item);
    },
    sendadd(item) {
      this.$emit("add", item);
    },
    sendchange(item) {
      this.$emit("change", item);
    },
    senddelete(item) {
      this.$emit("delete", item);
    },
    filterlist() {
      if (this.stock) this.items = this.list;
      else {
        this.items = this.list.filter((elm) => elm.cmd_id == this.cmd_id);
      }
      this.klist++;
    },
    excel_import(items) {
      this.$emit("excel_import", items);
    },
    SelectItem(item) {
      this.document = item;
      this.$emit("rowselect", item);
    },
    SelectItem2(item) {
      this.document2 = item;
      this.$emit("rowselect2", item);
    },
    close() {
      this.$emit("close");
    },
    save() {
      this.change = 0;
      if (this.$refs.form.validate()) {
        this.$emit("save", this.list, this.editedItem);
      }
    },
    open(item) {
      this.$emit("open", item);
    },
    open2(item) {
      this.$emit("open2", item);
    },
    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    sumCol(key) {
      // sum data in give key (property)
      return this.list.reduce((a, b) => a + (b[key] || 0), 0);
    },
    print_doc() {
      if (this.printdefault) {
        const addHeaders = (doc) => {
          const pageCount = doc.internal.getNumberOfPages();

          doc.setFont("helvetica", "italic");
          doc.setFontSize(8);
          for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            if (myLogo) {
              let marges = marges2(doc, this.$store);
              var dataimg = myLogo;
              doc.addImage(
                dataimg,
                "png",
                marges.margin_left,
                marges.margin_top,
                marges.l_w,
                marges.l_h
              );
            }
            doc.setFontSize(12);
            doc.text(this.title, 15, 48);
            doc.setFontSize(9);
            doc.text("Date : " + today, 170, 48);
          }
        };
        const addFooters = (doc) => {
          const pageCount = doc.internal.getNumberOfPages();

          doc.setFont("helvetica", "italic");
          doc.setFontSize(8);
          for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(
              "Page " + String(i) + " of " + String(pageCount),
              doc.internal.pageSize.width / 2,
              287,
              {
                align: "center",
              }
            );
          }
        };

        let head = [];
        let columns = [];
        let items = [];
        let foot = [];
        let item;
        //data formating

        for (let index = 0; index < this.list.length; index++) {
          item = {};
          this.listheaders.forEach((elm) => {
            if (elm.value == "no") {
              item[elm.value] = index + 1;
            }
            if (elm.slot == "cur22") {
              item[elm.value] = this.numberWithSpace(
                parseFloat(this.list[index][elm.value]).toFixed(2)
              );
            } else if (elm.slot == "cur" && this.list[index][elm.value]) {
              item[elm.value] = this.numberWithSpace(
                parseFloat(this.list[index][elm.value]).toFixed(2)
              );
            } else if (this.list[index][elm.value])
              item[elm.value] = this.list[index][elm.value];
          });
          items.push(item);
        }

        let columnStyles = {};
        var doc = new jsPDF(this.orientation);
        item = {};
        this.listheaders.forEach((element) => {
          head.push({
            content: element.text,
            styles: {
              halign:
                element.align == "end"
                  ? "right"
                  : element.align == "center"
                  ? "center"
                  : "left",
            },
          });

          if (element.totlib) {
            item[element.value] = "Totals";
          } else if (element.total != null) {
            if (typeof this.sumCol(element.total) == "number")
              item[element.value] = this.numberWithSpace(
                this.sumCol(element.total).toFixed(2)
              );
            else
              item[element.value] = this.numberWithSpace(
                element.total.toFixed(2)
              );
          }

          columns.push({ dataKey: element.value });
          columnStyles[element.value] = {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          };
        });

        if (this.Total) foot.push(item);

        doc.autoTable({
          margin: { top: 50 },
          headStyles: {
            valign: "middle",
            fillColor: [128, 128, 128],
          },
          columnStyles: columnStyles,
          head: [head],
          body: items,
          columns: columns,
          foot: foot,
          footStyles: {
            halign: "right",
            fillColor: [128, 128, 128],
          },
          showHead: "everyPage",
          showFoot: "everyPage",
        });
        addHeaders(doc);
        addFooters(doc);
        doc.setProperties({ title: this.title });
        doc.output("pdfobjectnewwindow");
      } else this.$emit("print");
    },
  },
};
</script>
